/* @font-face {
  font-family: "Helvetica Now Display";
  src: local("Helvetica Now Display Light"), local("HelveticaNowDisplay-Light"),
    url("HelveticaNowDisplay-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
} */

@font-face {
  font-family: "Helvetica Now Display";
  src: local("Helvetica Now Display Regular"),
    local("HelveticaNowDisplay-Regular"),
    url("HelveticaNowDisplay-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: local("Helvetica Now Display Medium"),
    local("HelveticaNowDisplay-Medium"),
    url("HelveticaNowDisplay-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: local("Helvetica Now Display Bold"), local("HelveticaNowDisplay-Bold"),
    url("HelveticaNowDisplay-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

/* @font-face {
  font-family: "Helvetica Now Display";
  src: local("Helvetica Now Display ExtBd"),
    local("HelveticaNowDisplay-ExtraBold"),
    url("HelveticaNowDisplay-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
} */
