@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
